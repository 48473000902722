@import '../../variables.scss';
@import '../../mixin.scss';

.footer {
  background: $BgBlack;
  .footerContainer {
      @include inner;
      max-width: 1440px;
      padding-top: 70px;
      .footerTop {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 80px;
        border-bottom: 0.5px solid $DarkenGrey;
        dl, dt, dd {
            margin: 0;
            padding: 0;
        }
        .logoWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            .peck {
                margin-top: 24px;
                background: $PureWhite;
                padding: 10px 12px;
                border-radius: 4px;
            }
        }
        .footerList {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            dl {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // margin-right: 100px;
            }
            dt{
                width: 100%;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.2;
                color: $Green;
            }
            dd {
                width: 100%;
                font-weight: 300;
                font-size: 12px;
                line-height: 1.2;
                color: $WhiteText;
                margin-top: 20px;
                a {
                    color: $WhiteText;
                    text-decoration: none;
                    &:hover {
                        color: $WhiteText;
                        text-decoration: underline;
                    }
                }
            }
            dl.communityWrap {
                dd {
                    width:50%;
                }
            }
        }

        .launchApp {
            @include greenBtn(10px 20px, 400);
        }
      }
      .footerBottom {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          .footerMessageContainer {
            font-size: 10px;
            line-height: 1.2;
            .footerMessage {
                font-weight: 300;
                font-size: 10px;
                color: $DarkenGrey;
            }
          }
          button {
              padding: 5px 10px;
          }
      }
  }
}


@media (max-width: 991.98px) { 
    .footer {
        .footerContainer {
            padding-top: 40px;
            padding: 40px 24px 0 24px;
            .footerTop {
                padding-bottom: 40px;
                .logoWrap {
                    order:1;
                    .logo {
                        align-self: center;
                    }
                }
                .launchApp {
                    order:2;
                }
                .footerList {
                    margin-top: 44px;
                    flex-wrap: nowrap;
                    order:3;
                    dl {
                        flex: 1;
                        margin-left: 10px;
                        margin-right: 10px;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &.communityWrap {
                            dd {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}


