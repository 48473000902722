@import '../../variables.scss';
@import '../../mixin.scss';

.link {
	color: $Green;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	margin-top: 16px;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	>img {
		margin-left: 4px;
	}
}