@import '../../variables.scss';
@import '../../mixin.scss';

.inputContainer {
	width: calc(100% - 44px);
	margin: 24px 22px 0 22px;

	&.inputContainerMax {
		input {
			padding-right: 46px;
		}
	}

	&.fullWidth {
		width: 100%;
		margin: 0;
	}

	&.textAlignLeft {
		input {
			text-align: left;
		}
	}

	&.hideSpin {
		input {
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
		}
	}

	.inputWrap {
		position: relative;
		display: inline-flex;
		width: 100%;

		.inputLogo {
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 20px;
		}

		input {
			border-bottom: 1px solid $LightenGrey;
			border-radius: 0;
			font-size: 28px;
			line-height: 33.6px;
			font-weight: 400;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 8px;

			&:focus {
				background: transparent;
				border-bottom: 1px solid $Green;
			}

			&::placeholder {
				color: $LGrey;
			}
		}

		.inputMax {
			margin: 0;
			padding: 0;
			border: 0;
			width: 40px;
			min-width: 40px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			position: absolute;
			right: 0;
			top: 50%;
			font-size: 12px;
			font-weight: 400;
			border-radius: 2px;
			background: rgba($Green, .16);
			color: $Green;
			transform: translateY(-50%);
			transition: all .2s ease;

			&:hover {
				bottom: 8px;
			}
		}
	}

	.inputWrapShowImg {
		input {
			text-align: left;
			font-size: 18px;
			line-height: 21.09px;
			padding-left: 28px;
		}
	}

	.inputWrapDisabled {
		.inputLogo {
			opacity: 0.5;
		}

		input {
			&:disabled {
				background: initial;
				cursor: not-allowed;
				color: $Grey;

				&::placeholder {
					color: $Grey;
				}
			}
		}
	}
}