 @mixin hover($bgColor) {
  box-shadow: 0 3px 11px 0 rgba($v2_bg-1, 0.3);
  background-image: linear-gradient(to right, rgba($v2_brand-b, 0), rgba($v2_brand-b, 0.3)),
                    linear-gradient(to right, $bgColor, $bgColor);
}

@mixin focus {
  box-shadow: 0 0 34px 0 rgba($v2_brand-b, 0.3), 0 0 2px 0 $v2_brand-b, 
              inset 0 0 3px 0 $v2_brand-b, 
              inset 0 0 23px 0 rgba($v2_brand-b, 0.3)!important;
  border: solid 1px $v2_brand-b!important;
}

@mixin pressed($bgColor, $borderColor) {
  box-shadow: inset 0 6px 14px 0 $v2_bg;
  background-color: $bgColor!important;
  border-color:$borderColor!important;
  color: $v2_text!important;
}

@mixin secondary($bgColor, $borderColor) {
  cursor: pointer;
  border: 1px solid $borderColor;
  background-color: $bgColor;
  color:$v2_text;
  &:hover {
    @include hover($bgColor);
  }
  &:focus {
    @include focus();
  }
  &:active {
    @include pressed($bgColor, $borderColor);
  }
  &:disabled {
    opacity: .5!important;
    cursor: not-allowed;
    &:hover {
      box-shadow: initial;
      background-image: initial;
    }
  }
}