// logo错位排放
.twinLogoLayout {
	position: relative;
	width: 36px;
	height: 36px;
	&.smallLayout {
		width: 26px;
		height: 26px;
	}

	.logo1 {
		width: 20px;
		height: 20px;
		&.small {
			width: 14px;
			height: 14px;
		}
	}

	.logo2 {
		position: absolute;
		left: 9px;
		top: 9px;
		width: 26px;
		height: 26px;
		&.small {
			left: 6px;
			top: 6px;
			width: 18px;
			height: 18px;
		}
	}
}

// logo并列排放
.twinLogoLayoutMode2 {
	position: relative;
	width: 44px;
	height: 26px;
	&.largeLayout {
		width: 82px;
		height: 48px;
	}
	.logo1,
	.logo2 {
		position: absolute;
		width: 26px;
		height: 26px;
		&.large {
			width: 48px;
			height: 48px;
		}
	}
	.logo1 {
		top: 0;
		left: 0;
	}
	.logo2 {
		position: absolute;
		top: 0;
		left: 40%;
	}
}

.twinLogoLayoutMode3 {
	position: relative;
	width: 100px;
	height: 100px;

	.logo1,
	.logo2 {
		position: absolute;
		width: 70px;
		height: 70px;
	}

	.logo1 {
		top: 15px;
		left: 0;
	}

	.logo2 {
		position: absolute;
		top: 15px;
		right: 0;
	}
}

.twinLogoLayoutMode4 {
	position: relative;
	width: 32px;
	height: 18px;

	.logo1,
	.logo2 {
		position: absolute;
		width: 18px;
		height: 18px;
	}

	.logo1 {
		top: 0;
		left: 0;
	}

	.logo2 {
		position: absolute;
		top: 0;
		left: 9px;
	}
}
