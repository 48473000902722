@import './variables.scss';
@import './mixin.scss';
@import "~bootstrap/scss/bootstrap.scss";

$theme-colors: (
    "primary": $Green,
    "darkPrimary": $Green,
    "Grey": $Grey,
    "lightGrey": $Bg,
    "darkgrey": $DarkGrey,
    "black": $DarkBlack,
    "white": $PureWhite,
    "secondary": $DarkBlack
);

*:focus {
    box-shadow: initial !important;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: initial !important;
}

body {
    background-color: $Bg;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

.container {
    @include inner;
}




//custom button variants
.btn:focus,
button:focus {
    outline: 0;
}


.btn {
    &:disabled {
        opacity: 0.38;
        pointer-events: initial;
        cursor: not-allowed;
    }
}

.btn-green {
    @include greenBtn(10px 20px);
    min-width: 42px;
}

.btn-blue {
    @include blueBtn(10px 20px);
    min-width: 42px;
}

.btn-outline-green-lg {
    border: 1px solid $Green;
    min-width: 42px;
    padding: 17px 17px 19px 18px;
    border-radius: 26px;
    background-color: #000;
    font-family: PingFang-SC, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.4;
    letter-spacing: normal;
    text-align: center;
    color: #bdff6b;


    &:hover {
        // color: darken($Green, 10%);
        color: #bdff6b;
        background-color: #000;
        // border: 1px solid darken($Green, 10%);
    }
}

.btn-outline-red-lg {
    padding: 19px 286px;
    border-radius: 30px;
    background-color: #000;
    font-family: PingFang-SC, Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #bdff6b;

    // &:hover {
    //     color: darken($Red, 10%);
    //     border: 1px solid darken($Red, 10%);
    // }
}

.btn-outline-green {
    min-width: 42px;
    padding: 12px 40px 12px 39px;
    border-radius: 20px;
    border: 1px solid #999;
    background-color: transparent;
    font-family: PingFang-SC, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333;
    text-align: center;

    &.btn-lg {
        padding: 8px 16px;
        border-radius: 22px;
        line-height: 16.8px;
    }

    &:hover {
        border: 1px solid $Green;
        background: $Green;
        color: $PureWhite;
    }

    &:active {
        border: 1px solid darken($Green, .2);
        background: linear-gradient(rgba($PureBlack, .2), rgba($PureBlack, .2)), linear-gradient($Green, $Green);
        color: $PureWhite;
    }

    &:disabled {
        opacity: .5;
        border: 1px solid $Green;
        color: $Green;
        background: transparent;
    }
}

.btn-outline-blue {
    font-size: 14px;
    font-weight: 500;
    color: $Blue;
    padding: 8px;
    border: 1px solid $Blue;
    border-radius: 16px;
    line-height: 14px;
    min-width: 42px;

    &:hover {
        border: 1px solid $Blue;
        background: $Blue;
        color: $PureWhite;
    }

    &:active {
        border: 1px solid darken($Blue, .2);
        background: linear-gradient(rgba($PureBlack, .2), rgba($PureBlack, .2)), linear-gradient($Blue, $Blue);
        color: $PureWhite;
    }

    &:disabled {
        opacity: .5;
        border: 1px solid $Blue;
        color: $Blue;
        background: transparent;
    }
}

.btn-outline-grey {
    font-size: 14px;
    color: $PureWhite;
    min-width: 88px;
    padding: 7px 22px;
    border: 1px solid $PureWhite;
    border-radius: 22px;
    font-weight: 500;
    min-width: 42px;

    &:hover {
        color: $PureWhite;
        border: 1px solid $PureWhite;
        background: rgba($PureWhite, .2);
    }

    &:active {
        opacity: .8;
        color: $PureWhite;
        border: 1px solid $PureWhite;
        background: rgba($PureWhite, .2);
    }

    &:disabled {
        opacity: .5;
        color: $LightenGrey2;
        border: 1px solid $LightenGrey2;
        background: transparent;
    }
}

.btn-selectAsset {
    margin: 0 auto;
    padding: 8px;
    border-radius: 8px;
    background: $LightenGrey2;
    min-width: 112px;
    justify-content: space-between !important;
}

.btn-selectAsset+.dropdown-menu {
    background-color: $LightenGrey2;
    max-height: 300px;
    overflow-y: auto;

    .dropdown-item {
        .active {
            background-color: $Green;
        }
    }
}

.btn-switchNetwork {
    // @include greenBtn(8px 16px);
    // color: $Green;
    // background: rgba($Green, .2);
    // min-width: 42px;
    // min-height: 38px;
    // font-weight: 500;
    padding: 6px 16px 6px 6px;
    border-radius: 26px;
    background-color: #000;

    // &:hover {
    //     color: $Green;
    //     background: rgba($Green, .3);
    // }

    // &:active {
    //     color: $Green;
    //     background: linear-gradient(rgba($PureBlack, .8), rgba($PureBlack, .8)), linear-gradient($Green, $Green);
    // }

    // &:after {
    //     background: url('./images/freshBank/arrow-red.svg') center center;
    // }
}

.btn-switchNetwork-wrong {
    @include greenBtn(8px 16px);
    color: $Red;
    background: rgba($Red, .2);
    min-width: 42px;
    min-height: 38px;
    font-weight: 500;
    padding: 5px 10px !important;

    &:hover {
        color: $Red;
        background: rgba($Red, .3);
    }

}



.selectAssetItem .active {
    background-color: $Green;
}

//table overrides
.table> :not(:first-child) {
    border-top: 0;
}

.dropdown-toggle {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.dropdown-menu {
    background-color: $BgBlack;
    color: $PureWhite;

    &:hover {
        color: $PureWhite;
    }

    .dropdown-item {
        color: $PureWhite;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 16px;

        &.active,
        &:active {
            background-color: $Green;
        }

        &:hover,
        &:focus {
            background-color: rgba($Green, .3);
        }
    }
}


.colorProgress {
    width: 100%;
    @include progressBar;
}

//custom modal
.modal {
    .modal-dialog {
        width: fit-content;
        max-width: none !important;

        .modal-content {
            background-color: $WhiteText;
            text-align: center;
            border-radius: 12px;
            border: 1px solid $WhiteText;
            padding: 20px;

            .modal-header {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;
                border: none;
                padding: 0;
                width: 100%;

                .logo {
                    margin-right: 4px;
                }

                .assetName {
                    font-family: PingFang-SC, Arial, Helvetica, sans-serif;
                    font-size: 39px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;
                }

                .btn-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-size: 12px;
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 0;

                .nav-pills {
                    width: 100%;
                    background-color: #EFEFF2;
                    padding: 4px;
                    border-radius: 20px;

                    .nav-item {
                        flex-grow: 1;

                        .nav-link {
                            font-size: 1em;
                            font-weight: 900;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #333;

                            &:hover {
                                cursor: pointer;
                                color: $Black;
                            }

                            &.active {
                                background-color: $PureWhite;
                                color: $DarkBlack;
                                font-weight: 500;
                                border-radius: 16px;
                            }
                        }
                    }
                }

                .tab-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            .modal-footer {
                padding: 0;
                width: 100%;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media (min-width: 576px) {
        .modal-dialog-centered {
            // min-height: calc(100% - 3.5rem);
            // max-width: 801px;
        }
    }
}

.form-control {
    border: none;
    text-align: center;
    background-color: transparent;
    font-size: 28px;
    font-weight: 400;
    color: $DarkBlack;
    padding-left: 0;
    padding-right: 0;

    &:focus {
        border: none;
        box-shadow: initial;
    }
}



.form-switch {
    .form-check-input {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        background-image:
            url('./images/freshBank/switch_line.svg'),
            url('./images/freshBank/switch_round.svg');

        &:checked {
            background-image:
                url('./images/freshBank/switch_checked_line.svg'),
                url('./images/freshBank/switch_checked_round.svg');
        }

        &:focus {
            box-shadow: initial;
        }
    }
}

// 
.tor-suspenseLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .spinner-border {
        color: $Green !important;
    }
}

.tor-pendingHashWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .pendingDesc {
        font-size: 12px;
        margin-left: 8px;
        margin-right: 4px;
    }

    .txnLink {
        color: $Green;
    }
}




// 

.customModal {
    .modal-dialog {
        width: fit-content;
        max-width: none !important;

        .modal-content {
            // min-height: 520px;

            .modal-body {
                justify-content: space-between;

                .tab-content {
                    flex: 1;

                    // height: 100%;
                    .tab-pane {
                        // flex: 1;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }
        }
    }


    .tab-pane {
        width: 100%;
    }

    .contentContainer {
        width: 100%;
        padding-top: 20px;

        .formContainer {
            width: 100%;
            // min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .formHd {
                .formTitle {
                    font-size: 18px;
                    font-weight: 500;
                    color: $DarkBlack;
                    margin-bottom: 8px;
                }

                .formSubtitle {
                    margin-top: 8px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: $Green;

                    >img {
                        width: 20px;
                        margin-left: 2px;
                    }
                }
            }

            .formHdTips {
                font-size: 1em;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: #333;
            }

            .inputAndSelectasset {
                padding: 26px 22px;
                border-radius: 12px;
                border: 0.5px solid $LightenGrey;
                width: 100%;

                .inputContainer {
                    margin: 0;
                    width: 100%;
                }
            }

            .inputContainer {

                width: calc(100% - 44px);
                margin: 24px 22px 0 22px;

                &.inputContainerMax {
                    input {
                        padding-right: 46px;
                    }
                }

                .inputWrap {
                    position: relative;

                    .inputLogo {
                        position: absolute;
                        bottom: 10px;
                        left: 0;
                        width: 20px;
                    }

                    input {
                        border-bottom: 1px solid $LightenGrey;
                        border-radius: 0;
                        font-size: 28px;
                        line-height: 33.6px;
                        font-weight: 400;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-left: 8px;

                        &:focus {
                            background: transparent;
                            border-bottom: 1px solid $Green;
                        }

                        &::placeholder {
                            color: $LGrey;
                        }
                    }

                    .inputMax {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        width: 40px;
                        min-width: 40px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        font-size: 12px;
                        font-weight: 400;
                        border-radius: 2px;
                        background: rgba($Green, .16);
                        color: $Green;
                        transform: translateY(-50%);
                        transition: all .2s ease;

                        &:hover {
                            bottom: 8px;
                        }
                    }
                }

                .inputWrapShowImg {
                    input {
                        text-align: left;
                        font-size: 18px;
                        line-height: 21.09px;
                        padding-left: 28px;
                    }
                }

                .inputWrapDisabled {
                    .inputLogo {
                        opacity: 0.5;
                    }

                    input {
                        &:disabled {
                            background: initial;
                            cursor: not-allowed;
                            color: $Grey;

                            &::placeholder {
                                color: $Grey;
                            }
                        }
                    }
                }

                .btn-group {
                    width: 100%;
                    margin-top: 15px;

                    .btn-check+.btn-outline-green {
                        line-height: 14.06px;
                        font-size: 12px;
                        font-weight: 400;
                        border: 1px solid $LightenGrey;
                        border-radius: 4px;
                        padding: 5px 12px;
                        margin-right: 16px;
                        color: $DarkenGrey;

                        &:last-child {
                            margin-right: 0;
                        }

                        &:hover {
                            border: 1px solid $LightGrey;
                            color: $DarkBlack;
                            background: transparent;
                        }

                        &:active {
                            background: transparent;
                        }
                    }

                    .btn-check:checked+.btn-outline-green {
                        border: 1px solid $Green;
                        background: rgba($Green, .1);
                        color: $Green;
                    }
                }
            }

            // .tipsContainer {
            //     .icon {
            //         font-size: 48px;
            //         color: $Green;
            //         &.error {
            //             color: $Red;
            //         }
            //         &.neutral {
            //             color: $Black;
            //         }
            //         &.small {
            //             font-size: 30px;
            //         }
            //     }
            //     .title {
            //         font-weight: 500;
            //         font-size: 18px;
            //         color: $DarkBlack;
            //         margin-top: 12px;
            //     }
            //     .msg {
            //         font-size: 14px;
            //         line-height: 16.8px;
            //         color: $DarkenGrey;
            //         font-weight: 400;
            //         padding: 0 30px;
            //         margin-top: 12px;
            //     }
            //     img {
            //         height: 48px;
            //     }
            //     &.smallIconTipsConainer {
            //         width: 100%;
            //         display: flex;
            //         flex-direction: row;
            //         align-items: center;
            //         padding: 12px 10px;
            //         border-radius: 8px;
            //         margin-bottom: 12px;
            //         &.smallIconError {
            //             background: rgba($Red, .2);
            //             >svg,
            //             .msg {
            //                 color: $Red;
            //             }
            //         }
            //         &.smallIconWarning {
            //             background: rgba($Red, .2);
            //             img {
            //                 height: 24px;
            //                 margin-right: 10px;
            //             }
            //             >svg,
            //             .msg {
            //                 color: $Red;
            //             }
            //         }
            //         .icon {
            //             font-size: 28px;
            //         }
            //         img,
            //         >svg {
            //             margin-right: 14px;
            //             height: 20px;
            //         }
            //         .msg {
            //             margin-top: 0px;
            //             padding: 0;
            //             font-weight: 400;
            //             line-height: 16.8px;
            //             font-size: 14px;
            //             text-align: left;
            //         }
            //     }
            // }

            .link {
                color: $Green;
                font-size: 14px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                margin-top: 16px;
                font-weight: 500;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }

                >img {
                    margin-left: 4px;
                }
            }

            .tile {
                background-color: $Bg;
                border-radius: 4px;
                padding: 20px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 10px;
                width: 300px;
                cursor: pointer;

                &:hover {
                    background-color: rgba($Bg, 0.7);
                }

                .data {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .label {
                        font-size: $xSmall;
                        color: $DarkGrey;
                    }

                    .value {
                        font-size: $xSmall;
                        color: $Black;
                        font-weight: 500;
                    }
                }

                .tokenName {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4px;

                    img {
                        margin: 0;
                    }
                }

                .swapEstimation {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: $xxSmall;
                    gap: 4px;

                    .label {
                        color: $DarkGrey;
                    }

                    .value {
                        color: $Black;
                    }
                }
            }
        }
    }

    .calcNumWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 16px;
        text-align: center;

        .calcNumWrap-hd {
            font-size: 14px;
            font-weight: 400;
            color: $Grey;
            line-height: 16.8px;
        }

        .calcNumWrap-bd {
            padding-left: 8px;
            font-size: 14px;
            line-height: 16.8px;
            color: $DarkBlack;
            font-weight: 500;

            img {
                display: inline-block;
                width: 20px;
                padding-left: 4px;
                vertical-align: top;
            }
        }
    }

    .loanUtilRate {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            .label {
                color: $Grey;
                font-size: 14px;
                font-weight: 400;
            }

            .value {
                margin-left: 6px;
                font-size: 14px;
                font-weight: 500;
            }

            .value-normal {
                color: $DarkBlack;
            }

            .value-yellow {
                color: $Yellow;
            }

            .value-red {
                color: $Red;
            }
        }

        .msg {
            margin-top: 20px;
            font-weight: 400;
            font-size: 12px;
            color: $Grey;

        }

        .loanBarOuter {
            width: 100%;
            height: 4px;
            background-color: $LightenGrey2;
            border-radius: 4px;
            overflow: hidden;

            .loanBarInner {
                border-radius: 4px;
                height: 4px;
            }

            .loanBarInner-normal {
                background-color: $Green;
            }

            .loanBarInner-yellow {
                background-color: $Yellow;
            }

            .loanBarInner-red {
                background-color: $Red;
            }
        }
    }

    .modalListTips {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        border-radius: 12px;
        margin-top: 24px;
        background: $LightenGrey2;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-bottom: 6px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            line-height: 1.5;
            font-size: 10px;
            letter-spacing: 0;
            margin-bottom: 8px;

            .liHd {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                color: $Grey;
            }

            .liBd {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                color: $DarkBlack;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .buttonsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .btn {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
        }

        .primaryButton {
            padding: 0.5em 0;
            width: 100%;
            border-radius: 2em;
            font-size: 1em;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: black;

            &:disabled {
                background-color: #c2c3cc;
                cursor: not-allowed;
            }
        }
    }

    .footerList {
        margin-top: 16px;

        .footerItem {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;

            .label {
                font-size: 14px;
                font-weight: 400;
                color: $Grey;
            }

            .value {
                font-size: 14px;
                font-weight: 500;
                color: $DarkBlack;
            }
        }
    }

    .tipsCheckWrap {
        margin-top: -4px;
        margin-bottom: 12px;

        label {
            margin-left: 6px;
            cursor: pointer;
        }
    }
}



.tor-tooltip {
    border-radius: 4px;

    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        text-align: left;
        max-width: 300px;
        background-color: $PureWhite;
        color: $DarkenGrey;
        font-size: 14px;
        font-weight: 400;
        box-shadow: 0px 0 15px rgba(29, 37, 47, 0.16);
    }

    .tooltip-arrow {
        &:before {
            border-top-color: $PureWhite;
        }
    }
}

.tor-tooltip-Line {
    display: inline-flex;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    cursor: pointer;

    &:hover {
        color: $Green;
    }
}

.tor-tooltip-without-line {
    display: block;
    cursor: pointer;

    &:hover {
        color: $Green;
    }
}

.tor-tooltip-Icon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;

    .tor-tooltip-infoIcon {
        cursor: pointer;
        display: inline-block;
        margin-left: 4px;
        width: 16px;
        height: 16px;
        background: url('./images/freshBank/info_gray.svg') no-repeat center center;

        &:hover {
            background-image: url('./images/freshBank/info.svg');
        }
    }
}

.tor-tooltip-Icon-white {
    .tor-tooltip-infoIcon {
        opacity: 0.38;
        background: url('./images/freshBank/info_gray2.svg') no-repeat center center;

        &:hover {
            background-image: url('./images/freshBank/info_white.svg');
            opacity: 1;
        }
    }
}

.tor-dropdown {
    .dropdown-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:after {
            margin-left: 10px;
            width: 10px;
            height: 10px;
            border: 0;
            background: url('./images/freshBank/arrow.svg') center center;
        }
    }

    .dropdown-menu {
        border: 1px solid $Green;
        border-radius: 8px;
        top: calc(100% + 10px);

        >a {
            min-width: 197px;
            padding: 10px 16px;

            >img {
                height: 20px;
            }

            >span {
                font-weight: 400;
                font-size: 14px;
                line-height: 16.8px;
                margin-left: 6px;
                color: $LightenGrey2;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: rgba($Green, .2);
            }
        }
    }

    .show {
        .dropdown-toggle {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.tor-dropdown {
    .btn-switchNetwork-wrong {
        &:after {
            background: url('./images/freshBank/arrow-red.svg') center center;
        }
    }
}


@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(365deg);
    }
}


.tor-pagination {
    margin-bottom: 0;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .page-item {
        display: flex;
        align-items: center;
        justify-content: center;

        .page-link {
            padding: 6px;
            box-shadow: initial !important;
            border: 0;
            background: transparent;
            font-size: 24px;
            line-height: 1;
            color: $LGrey;

            &:hover {
                color: $DarkenGrey;
            }
        }

        &:first-child {
            margin-top: -4px;
        }

        &:last-child {
            margin-top: -4px;
        }

        &.active {
            .page-link {
                margin-right: 0;
                color: $Green;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}




.swiper {
    width: 100%;
    height: 100%;
}


.tipsOnChart {
    box-shadow: 5px 5px 10px rgba(black, 0.2);
    width: fit-content;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000000;
    background-color: white;
    cursor: default;
    visibility: hidden;
    padding: 6px;
}

.middleTipsOnChart {
    position: absolute;
    width: 100%;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;

    img {
        margin-bottom: 12px;
    }
}

.emptyMessageContainer {
    // background-color: red;
}