@import '../../variables.scss';
@import '../../mixin.scss';

.contentContainer {
	width: 100%;
	min-width: 420px;
	padding-top: 2em;
	// height: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;

	.formContainer {
		width: 100%;
		// min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;

		.formHd {
			.formTitle {
				font-size: 18px;
				font-weight: 500;
				color: $DarkBlack;
				margin-bottom: 8px;
			}

			.formSubtitle {
				margin-top: 8px;
				margin-bottom: 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 500;
				color: $Green;

				>img {
					width: 20px;
					margin-left: 2px;
				}
			}
		}

		.formHdTips {
			font-size: 14px;
			line-height: 16.8px;
			font-weight: 400;
			color: $DarkenGrey;
		}

		.inputAndSelectasset {
			padding: 26px 22px;
			border-radius: 12px;
			border: 0.5px solid $LightenGrey;
			width: 100%;

			.inputContainer {
				margin: 0;
				width: 100%;
			}
		}

		.inputContainer {

			width: calc(100% - 44px);
			margin: 24px 22px 0 22px;

			&.inputContainerMax {
				input {
					padding-right: 46px;
				}
			}

			.inputWrap {
				position: relative;

				.inputLogo {
					position: absolute;
					bottom: 10px;
					left: 0;
					width: 20px;
				}

				input {
					border-bottom: 1px solid $LightenGrey;
					border-radius: 0;
					font-size: 28px;
					line-height: 33.6px;
					font-weight: 400;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 8px;

					&:focus {
						background: transparent;
						border-bottom: 1px solid $Green;
					}

					&::placeholder {
						color: $LGrey;
					}
				}

				.inputMax {
					margin: 0;
					padding: 0;
					border: 0;
					width: 40px;
					min-width: 40px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					position: absolute;
					right: 0;
					top: 50%;
					font-size: 12px;
					font-weight: 400;
					border-radius: 2px;
					background: rgba($Green, .16);
					color: $Green;
					transform: translateY(-50%);
					transition: all .2s ease;

					&:hover {
						bottom: 8px;
					}
				}
			}

			.inputWrapShowImg {
				input {
					text-align: left;
					font-size: 18px;
					line-height: 21.09px;
					padding-left: 28px;
				}
			}

			.inputWrapDisabled {
				.inputLogo {
					opacity: 0.5;
				}

				input {
					&:disabled {
						background: initial;
						cursor: not-allowed;
						color: $Grey;

						&::placeholder {
							color: $Grey;
						}
					}
				}
			}

			.btn-group {
				width: 100%;
				margin-top: 15px;

				.btn-check+.btn-outline-green {
					line-height: 14.06px;
					font-size: 12px;
					font-weight: 400;
					border: 1px solid $LightenGrey;
					border-radius: 4px;
					padding: 5px 12px;
					margin-right: 16px;
					color: $DarkenGrey;

					&:last-child {
						margin-right: 0;
					}

					&:hover {
						border: 1px solid $LightGrey;
						color: $DarkBlack;
						background: transparent;
					}

					&:active {
						background: transparent;
					}
				}

				.btn-check:checked+.btn-outline-green {
					border: 1px solid $Green;
					background: rgba($Green, .1);
					color: $Green;
				}
			}
		}

		// .tipsContainer {
		//     .icon {
		//         font-size: 48px;
		//         color: $Green;
		//         &.error {
		//             color: $Red;
		//         }
		//         &.neutral {
		//             color: $Black;
		//         }
		//         &.small {
		//             font-size: 30px;
		//         }
		//     }
		//     .title {
		//         font-weight: 500;
		//         font-size: 18px;
		//         color: $DarkBlack;
		//         margin-top: 12px;
		//     }
		//     .msg {
		//         font-size: 14px;
		//         line-height: 16.8px;
		//         color: $DarkenGrey;
		//         font-weight: 400;
		//         padding: 0 30px;
		//         margin-top: 12px;
		//     }
		//     img {
		//         height: 48px;
		//     }
		//     &.smallIconTipsConainer {
		//         width: 100%;
		//         display: flex;
		//         flex-direction: row;
		//         align-items: center;
		//         padding: 12px 10px;
		//         border-radius: 8px;
		//         margin-bottom: 12px;
		//         &.smallIconError {
		//             background: rgba($Red, .2);
		//             >svg,
		//             .msg {
		//                 color: $Red;
		//             }
		//         }
		//         &.smallIconWarning {
		//             background: rgba($Red, .2);
		//             img {
		//                 height: 24px;
		//                 margin-right: 10px;
		//             }
		//             >svg,
		//             .msg {
		//                 color: $Red;
		//             }
		//         }
		//         .icon {
		//             font-size: 28px;
		//         }
		//         img,
		//         >svg {
		//             margin-right: 14px;
		//             height: 20px;
		//         }
		//         .msg {
		//             margin-top: 0px;
		//             padding: 0;
		//             font-weight: 400;
		//             line-height: 16.8px;
		//             font-size: 14px;
		//             text-align: left;
		//         }
		//     }
		// }

		// .link {
		// 	color: $Green;
		// 	font-size: 14px;
		// 	display: flex;
		// 	flex-direction: row;
		// 	justify-content: center;
		// 	align-items: center;
		// 	text-decoration: none;
		// 	margin-top: 16px;
		// 	font-weight: 500;

		// 	&:hover {
		// 		cursor: pointer;
		// 		text-decoration: underline;
		// 	}

		// 	>img {
		// 		margin-left: 4px;
		// 	}
		// }

		.tile {
			background-color: $Bg;
			border-radius: 4px;
			padding: 20px 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
			margin-bottom: 10px;
			width: 300px;
			cursor: pointer;

			&:hover {
				background-color: rgba($Bg, 0.7);
			}

			.data {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 10px;

				.label {
					font-size: $xSmall;
					color: $DarkGrey;
				}

				.value {
					font-size: $xSmall;
					color: $Black;
					font-weight: 500;
				}
			}

			.tokenName {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: 4px;

				img {
					margin: 0;
				}
			}

			.swapEstimation {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				font-size: $xxSmall;
				gap: 4px;

				.label {
					color: $DarkGrey;
				}

				.value {
					color: $Black;
				}
			}
		}
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 2em;

	.btn {
		flex: 1;
		font-size: 14px;
		font-weight: 500;
		line-height: 16.41px;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		border: none;
		outline: none;
		width: 100%;
		border-radius: 30px;
		font-size: 1.5em;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		background-color: black;
		color: #fff;

		&:disabled {
			background-color: #c2c3cc;
			color: #fff;
		}
	}

}

@media screen and (max-width: 1441px) {
	.buttonsContainer {
		.btn {
			font-size: 1em;
		}
	}
}

@media screen and (max-width: 429px) {
	.contentContainer {
		min-width: calc(100vw - 3.6em);
	}
}