@import '../../variables.scss';
@import '../../mixin.scss';



  
  .searchWrap {
    position: relative;
    width: 260px;
    height: 36px;
    .inputContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      input {
        width:100%;
        height: 100%;
        @include rubik();
        @include input;
        border-radius: 11px;
        font-size: 16px;
        padding: 7px 32px 7px 10px;
      }
      .searchBtn {
          position: absolute;
          right:4px;
          top:4px;
          @include tertiary-A;
          width: 26px;
          height: 26px;
          border-radius: 11px;
          display:flex;
          justify-content: center;
          align-items: center;
          img {
            width: 16px;
          }
      }
    }

  .suggList {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    &.active {
      color: $v2_brand-g;
    }
  }
  .suggLogo {
      margin-right: 5px;
  }
  
    :global {
      .react-autosuggest__container {
          position: relative;
        }
        
        .react-autosuggest__input {
          padding: 4px 8px;
        }
        
        .react-autosuggest__input--focused {
          outline: none;
        }
        
        .react-autosuggest__input--open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        
        .react-autosuggest__suggestions-container {
          display: none;
        }
        
        .react-autosuggest__suggestions-container--open {
          display: block;
          position: absolute;
          top: 100%;
          left: -12px;
          right: -12px;
          margin-top: 18px;
          border-top:0;
          max-height: 300px;
          overflow-y: auto;
          z-index: 2;
          @include card-A(26px);
          padding:20px 12px;

          &::-webkit-scrollbar{
            width: 8px;
            height:100%;
            background: transparent;
          }
          &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 5px rgba($v2_black,.3);
            background: transparent;
          }
          &::-webkit-scrollbar-thumb{
            @include card-A;
          }
          &::-webkit-scrollbar-button{
            display: none;
          }
          &::-webkit-scrollbar-button:vertical:end:increment,
          &::-webkit-scrollbar-button:vertical:start:decrement{
            display: block;
            height: 10px;
            background-color: transparent;
          }
        }
        
        .react-autosuggest__suggestions-list {
          margin: 0;
          padding: 0;
          list-style-type: none;
          border:0;
        }
        
        .react-autosuggest__suggestion {
          cursor: pointer;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        
        .react-autosuggest__suggestion--highlighted {
          // background-color: transparent;
          // color: #fff;
        }
  
    }
  }

