@import '../../variables.scss';
@import '../../mixin.scss';

.connectWalletModal {
  :global {
    .modal-dialog {
      max-width: fit-content;

      .modal-content {
        .modal-header {
          justify-content: flex-start;
          margin-bottom: 16px;

          h2 {
            font-size: 1.2em;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            text-align: left;
          }
        }
      }

      .modal-body {
        .contentContainer {
          padding-top: 0;

          .formContainer {
            height: fit-content;
            overflow-y: scroll;
            justify-content: flex-start;
            align-items: flex-start;
          }
        }
      }
    }
  }

  .formWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

    .desc {
      font-size: 1em;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333;
    }

    .btnGroup {
      width: fit-content;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 100%;

      .button {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1em;
        border-radius: 1em;
        background-color: #fff;
        gap: 1em;

        &:hover {
          background: $LineGrey;
        }

        >img {
          height: 1em;
        }

        .msg {
          font-size: 1em;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #333;
        }
      }
    }
  }
}

.link {
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  font-size: small;
  font-weight: bold;
}


.metamaskToast {

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt {
    display: flex;
    flex-direction: row;
    align-items: center;

    >img {
      width: 24px;
      height: 24px;
    }

    >span {
      margin-left: 12px;
      font-size: 20px;
      font-weight: 500;
      color: $DarkBlack;
    }


  }

  dd {
    margin-top: 9px;
    margin-left: 36px;

    a {
      color: $Green;
      line-height: 1.5;
      font-size: 14px;
    }
  }

}