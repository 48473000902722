//color template for dark theme
$Green: #4FDAB8;
$LightBlue: #65AEEF;
$Blue: #4F789C;
$Yellow:#BDB780;
$LightBg: #1A212D;
$DarkBg: #111722;
$ExtraDarkBg: #0D1119;
$Red: #FB7777;
$VersionDarkBg: #1d2535;

// aboutUS
$aboutus_Grey: #adb5bd;
$aboutus_MainTitle: #28d19a;
$aboutus_SubTitle: #e9b768;
$aboutus_sectionTitle: #bdb780;
$aboutus_sectionTitleBg: #234547;

// v2
$v2_white: #fff;
$v2_black: #000;
$v2_text: green; // rgb(232 235 243)

$v2_brand-a: #de35ff; // rgb(222 53 255)
$v2_brand-b: #25b4ff; // rgb(37 180 255)
$v2_brand-c: #5d5bff; // rgb(93 91 255)
$v2_brand-d: #c724ff; // rgb(199 36 255)
$v2_brand-e: #2512ff; // rgb(37 18 255)
$v2_brand-f: #1635ff; // rgb(22 53 255)
$v2_brand-g: #2c99d8; // rgb(44 153 216)
$v2_brand-h: #001b4d; // rgb(0 27 77)
$v2_brand-i: #e355ff; // rgb(227 85 255)
$v2_brand-j: #3087c5; // rgb(48 135 197)
$v2_brand-k: #000819; // rgb(0 8 25)
$v2_brand-l: #001a4d; // rgb(0 26 77)
$v2_brand-m: #bd2aff; // rgb(189 42 255)
$v2_brand-n: #0b2c63; // rgb(11 44 99)
$v2_brand-o: #e8ecf3; // rgb(232 236 243)
$v2_brand-p: #00112d; // rgb(0 17 45)
$v2_brand-q: #bcc8e8; // rgb(188 200 232)

$v2_bg-1: #000a29; // rgb(0 10 41)
$v2_bg: #001744; // rgb(0 23 68)
$v2_bg-3: #042158; // rgb(4 33 88)
$v2_bg-4:#08326d; // rgb(8 50 109)
$v2_bg-5:#0e4281; // rgb(14 66 129)
$v2_bg-6: #145294; // rgb(20 82 148)
$v2_bg-7: #1c63a6; // rgb(28 99 166)

$v2_warning:#ff9f44; // rgb(255 159 68)
$v2_error: #ff4d8f; // rgb(255 77 143)
$v2_success: #18ffc9; // rgb(24 255 201)





//color template
$Primary: #0955DA;
$LightPrimary: #3276F5;
$DarkPrimary: #003CBE;
$Grey: #BDC5D0;

$Yellow: #ff9f44;
$Negative: #D45850;

//Font sizes
$xxxSmall: 0.5em;
$xxSmall: 0.75em;
$xSmall: 0.85em;
$Small: 1em;
$Medium: 1.5em;
$Large: 2em;
$xLarge: 2.5em;
$xxLarge: 3em;
$xxxLarge: 4em;



$Bg: #F5F5F5;
$PureWhite: #FFF;
$PureBlack: #000;
$BgBlack: #060709;
$WhiteText: #F8F9FD;


$Grey: #7F8393;
$LGrey: #B4B7C1;
$LightGrey: #BDC5D0;
$LightenGrey: #DBDBE5;
$LightenGrey2: #EFEFF2;
$LightenGrey3: #666;
$DarkGrey: #646C77;
$DarkenGrey: #494E67;
$DarkBlack: #040A2D;
$Black: #212325;
$LineGrey: #E6E9EF;




$Green: #00D092;
$Green2: #04BEBE;
$Blue: #3784F9;
$Yellow: #ff9800;
$Yellow2: #FF8413;
$Red: #FB6491;
$Red2: #F84752;
$purple: #A162F1;




