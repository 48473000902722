@import '../../variables.scss';
@import '../../mixin.scss';

.sliderBlock {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;

	input {
		width: 100%;
	}

	.thumb {
		@include Barlow;
		border-radius: 24px;
		background-color: #00D092;
		color: white;
		padding: 0.2em 0.5em;
		font-weight: 700;
		position: absolute;
		pointer-events: none;
		width: 3em;
		text-align: center;
	}

	.slideThrought {
		height: 6px;
		border-radius: 6px;
		background-color: #00D092;
		position: absolute;
		pointer-events: none;
		left: 0;
	}

	input[type="range"] {
		-webkit-appearance: none;
		width: 100%;
		border-radius: 6px;
		height: 6px;
		// box-shadow: inset 0 7px 46px 0 rgba(0, 8, 25, 0.2), inset 0 1px 5px 0 #00D092;
		// border: solid 1px rgba(37, 180, 255, 0.12);
		// background-color: #00D092;
	}

	input[type="range"]:focus {
		outline: none;
	}

	input[type="range"]::-moz-range-thumb {
		-webkit-appearance: none;
		border: none;
		// background-color: #00D092;
		padding: 0.2em 0.5em;
	}

	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		border: none;
		// background-color: #00D092;
		padding: 0.2em 0.5em;
	}

	input[type="range"]::-ms-thumb {
		-webkit-appearance: none;
		border: none;
		// background-color: #00D092;
		padding: 0.2em 0.5em;
	}
}