@import "../../variables.scss";
@import "../../mixin.scss";

dl,dt,dd {
  margin:0;
  padding:0;
}

:global {
  .toastTransition-enter {
    opacity: 0;
    transform: translateX(2000px);
  }
  .toastTransition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .toastTransition-exit {
    opacity: 1;
  }
  .toastTransition-exit-active {
    opacity: 0;
    transition: opacity 500ms, transform 500ms;
  }
}


.toast {
  max-width: 500px;
  width: initial;
  position: fixed;
  top: 20px;
  right:20px;
  background: $PureWhite;
  border-radius: 8px;
  border-left: 8px solid $Grey;
  padding: 34px 24px;
  z-index: 999;
}

.toastError {
  border-left: 8px solid $Red2;
}


.toastSuccess {
  border-left: 8px solid $Green;
}


@media (max-width: 575.98px) { 
  .toast {
    position: fixed;
    right: 20px;
    left: 20px;
  }
}