@import '../../variables.scss';
@import '../../mixin.scss';

.myWalletModal {
  :global {
    .modal-dialog {
      max-width: fit-content;

      .modal-content {
        // min-height: 350px;

        .modal-header {
          justify-content: flex-start;
          margin-bottom: 16px;

          h2 {
            font-size: 1.2em;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            text-align: left;
          }
        }

        .modal-body {
          .contentContainer {
            padding-top: 0;

            .formContainer {
              min-height: initial;
            }
          }
        }

        .modal-footer {
          button {
            width: 100%;
            padding: 0.8em 0;
            border-radius: 2em;
            background-color: #000;
            border: none;
            outline: none;
            font-size: 1em;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #bdff6b;
          }
        }
      }
    }
  }

  .copySuccess {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background: $LightenGrey2;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba($Grey, .1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    >img {
      height: 20px;
      margin-right: 4px;
    }

    >span {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      color: $DarkBlack;
    }
  }

  .formWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .walletAddress,
    .network {
      .title {
        font-size: 1em;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #333;
        text-transform: capitalize;
        margin-bottom: 0.5em;
      }

      .content {
        padding: 1em;
        border-radius: 1em;
        background-color: #fff;
        font-size: 1em;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #666;
        width: 100%;

        img {
          height: 1em;
        }
      }
    }

    .walletAddress {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .content {
        display: flex;
        flex-direction: row;

        span {
          flex: 1 0 26px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        img {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }

    .network {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;

      img {
        margin-right: 8px;
      }
    }

    .viewBlockChain {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 23px;
      cursor: pointer;
      transition: all .2s ease;
      text-decoration: none;

      >span {
        font-size: 1em;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #666;
      }

      >img {
        margin-left: 4px;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}