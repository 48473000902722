@import '../../variables.scss';
@import '../../mixin.scss';

.btnGroup {
	width: 100%;
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	gap: 29px;
	justify-content: space-between;

	&.disabled {
		filter: grayscale(70%);
		pointer-events: none;
	}

	label {
		background-color: transparent;
		font-size: 1em;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		color: #333;

		// &:last-child {
		// 	margin-right: 0;
		// }

		// &:hover {
		// 	border: 1px solid $LightGrey;
		// 	color: $DarkBlack;
		// 	background: transparent;
		// }

		// &:active {
		// 	background: transparent;
		// }
	}

	input:checked+label {
		border: 1px solid $Green;
		background: rgba($Green, .1);
		color: $Green;
	}

	&.cleanUp {
		input:checked+label {
			border: 1px solid $LightenGrey;
			background: transparent;
			color: $DarkenGrey;
		}
	}
}

.sliderBlock {
	background: #EFEFF2;
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px;
	width: calc(80% + 4px);
	margin-right: 1em;
}

:global {
	.btn-group {
		.btn {
			padding: 0.5em 2em;
			border-radius: 20px !important;
			border: solid 1px #999;

			&:hover {
				border: solid 1px #999 !important;
			}
		}
	}
}

@media (max-width: 429px) {
	.btnGroup {
		flex-direction: column;
		gap: 0.5em;
	}
}