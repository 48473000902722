@import '../../variables.scss';
@import '../../mixin.scss';

.modalErrorTips {
  color: $v2_text;
  max-width: initial;
  .alertMsg {
    @include daysOne;
    font-size: 16px;
    line-height: 1.5;
  }
}