@import '../../variables.scss';
@import '../../mixin.scss';

.statusLabel {
  height: 24px;
  line-height: 24px;
  z-index:15;
  font-size: 12px;
  @include daysOne;
  border-radius: 10px;
  white-space: nowrap;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display:flex;
  align-items: center;
  padding: 0 8px;
  color: $v2_bg;
  >img,
  >svg {
      width: 14px;
      height: 14px;
  }
  .txt {
    margin-left: 2px;
  }
}
.inactive {
  background: rgba($v2_brand-q, 0.79);
  border: 1px solid $v2_brand-q;
}
.active {
  background: rgba(#18ffc9, 0.79);
  border: 1px solid #18ffc9;
}
.passed,
.custom {
  background: rgba($v2_brand-b, 0.79);
  border: 1px solid $v2_brand-b;
}
.rejected {
  background: rgba($v2_brand-a, 0.79);
  border: 1px solid $v2_brand-a;
}
.completed {
  background: rgba($v2_brand-b, 0.79);
  border: 1px solid $v2_brand-b;
}
.custom {
  @include rubik;
  line-height: 1.5;
}