@import '../../variables.scss';
@import '../../mixin.scss';


.selectAssetContainer {
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
  img {
    width: 20px;
  }
  .symbol {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: $DarkBlack;
  }
}

.selectAssetItem {
  img {
    width: 20px;
  }
  .symbol {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: $DarkBlack;
  }
}