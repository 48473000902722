@import '../../variables.scss';
@import '../../mixin.scss';

.tipsLayout {
	// margin-bottom: 2em;

	.icon {
		font-size: 48px;
		color: $Green;

		&.error {
			color: $Red;
		}

		&.neutral {
			color: $Black;
		}

		&.small {
			font-size: 30px;
		}
	}

	.title {
		font-weight: 500;
		font-size: 18px;
		color: $DarkBlack;
		margin-top: 12px;
	}

	.msg {
		font-size: 14px;
		line-height: 16.8px;
		color: $DarkenGrey;
		font-weight: 400;
		padding: 0 30px;
		margin-top: 12px;
	}

	img {
		height: 48px;
	}

	&.smallIconTipsConainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 12px 10px;
		border-radius: 8px;
		margin-bottom: 12px;

		&.smallIconError {
			background: rgba($Red, .2);

			>svg,
			.msg {
				color: $Red;
			}
		}

		&.smallIconWarning {
			background: rgba($Red, .2);

			img {
				height: 24px;
				margin-right: 10px;
			}

			>svg,
			.msg {
				color: $Red;
			}
		}

		.icon {
			font-size: 28px;
		}

		img,
		>svg {
			margin-right: 14px;
			height: 20px;
		}

		.msg {
			margin-top: 0px;
			padding: 0;
			font-weight: 400;
			line-height: 16.8px;
			font-size: 14px;
			text-align: left;
		}
	}
}