@import './variables';

.App {
  color: $Black;
  background-color: black;
  background-image: linear-gradient(to top, #aeb0c7, #f1f5ed);
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: top;
}

@media screen and (max-width: 1441px) {
  .App {
    background-size: auto 100vh;
  }
}